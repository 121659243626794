import Article from "./Article";

export default function WritingSection({title, articles, isLoading}) {
  let display = articles?.map(article => {
    return <Article key={article.title} {...article} />;
  });

  let helper = (
    <div className="col-lg-4 col-md-6 sm-margin-b-50">
      <h4>{isLoading ? "Loading..." : "Oops, something went wrong."}</h4>
    </div>
  );

  return (
    <>
      <div className="container content-lg">
        <div className="row margin-b-40">
          <div className="col-sm-6">
            <h2 className="portfolio-section">{title}</h2>
          </div>
        </div>
        <div className="row">
          {articles ? display : helper}
        </div>
      </div>
    </>
  );
};
