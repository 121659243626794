const baseUrl = "https://res.cloudinary.com/ddiz3qvnk/image/upload/";
const folder = "v1668794431/lina/";
const images = {
  china: "china_rcmoho.jpg",
  china2: "china2_ffjdyy.jpg",
  japan: "japan_i8arfl.jpg",
  middleclass: "middleclass_ilnrqz.jpg",
  party: "party_zgvid9.jpg",
  singapore: "singapore_w0afkh.jpg",
  srilanka: "srilanka_bpcbh3.jpg",
  thailand: "thailand_cwd5p0.jpg",
  volcano: "koryaksky_xnhl3l.jpg",
};

export { baseUrl, folder, images };
