import Highlight from "./Highlight";

export default function Skills() {
  return (
    <>
      <div className="bg-color-sky-light" data-auto-height="true">
        <div className="container content-lg">
          <div className="row row-space-2 margin-b-4">
            <h2>What I Do</h2>
            <Highlight 
              title="Storytelling" 
              description="Discovering new angles, crafting the stories people want to know about." 
              icon="fas fa-edit"
              />
            <Highlight 
              title="Leadership" 
              description="Building teams, setting editorial vision, training newsroom power players." 
              icon="far fa-compass"
              />
            <Highlight 
              title="SEO & Analytics" 
              description="Headline writer, metrics reader. Optimizer of content for search and social." 
              icon="fas fa-chart-line" />
            <Highlight 
              title="Digital Publishing" 
              description="I move fast because things move fast. And I can package the hell out of a story." 
              icon="fas fa-mobile-alt"
              />
          </div>
        </div>
      </div>
    </>
  )
}
