import { Outlet } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { checkCurrentPath, transformPath } from "../pathHelper";

export default function Writing({sections}) {
  const location = useLocation();

  return (
    <>
      <div className="container margin-t-40">
        <div className="row">
          <h1 className="text-center">Writing</h1>
          <ul className="nav justify-content-center">
            {/* <li className="nav-item">
              <Link className="nav-link" to="/#writing">Back</Link>
            </li> */}
            {sections?.map(section => {
              let path = transformPath(section)
              return (
                <li className="nav-item" key={section}>
                  <Link 
                    className={`nav-link portfolio-section ${checkCurrentPath(location.pathname, section, sections[0])}`} 
                    to={path}
                  >
                    {section}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <Outlet />
    </>
  );
};
