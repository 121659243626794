export default function Footer() {
  return (
    <div id="contact" className="margin-b-60">
      <div className="container content-lg">
        <div className="row margin-b-40">
          <div className="col-sm-6">
            <h2>Contact Me</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 md-margin-b-30">
            <h4>Location</h4>
            <p className="fake-link">Singapore</p>
          </div>
          <div className="col-lg-3 col-md-6 md-margin-b-30">
            <h4>Email</h4>
            <a href="mailto:contact@linabatarags.com">contact@linabatarags.com</a>
          </div>
          <div className="col-lg-3 col-md-6 md-margin-b-30">
            <h4>LinkedIn</h4>
            <a href="https://www.linkedin.com/in/lina-batarags/" target="blank">@lina-batarags</a>
          </div>
          <div className="col-lg-3 col-md-6 md-margin-b-30">
            <h4>Twitter</h4>
            <a href="https://twitter.com/LinaBatarags" target="blank">@LinaBatarags</a>
          </div>
        </div>
      </div>
    </div>
  )
}
