import Bio from "./Bio";
import Education from "./Education";
import Footer from "./Footer";
import Portfolio from "./Portfolio";
import Skills from "./Skills";
import "./About.css";

export default function About({sections, isLoading}) {
  return (
    <>
      <Bio />
      <Skills />
      <Portfolio sections={sections} isLoading={isLoading}/>
      <Education />
      <Footer />
    </>
  )
}
