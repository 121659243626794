import { Link } from "react-router-dom";
import { transformPath } from "../pathHelper";
import "./Portfolio.css";

export default function Portfolio({sections, isLoading}) {
  let display = sections.map((section, index) => {
    let path = transformPath(section);
    let num = index + 1;
    let portfolioNum = num > 9 ? num : `0${num}`;

    return (
      <Link
        to={`writing/${path}`}
        className='col-lg-4 col-md-6 margin-b-10'
        key={section}
      >
        <div className='service bg-color-sky-light' data-height='height'>
          <div className='service-element'>
            <h6 className='portfolio-num'>{portfolioNum}</h6>
          </div>
          <div className='text-center'>
            <h3 className='portfolio-section'>{section}</h3>
          </div>
        </div>
      </Link>
    );
  });

  let helper = (
    <div className="col-lg-3 col-md-6 md-margin-b-30">
      <h4 className="portfolio-section">{isLoading ? "Loading..." : "Oops, something went wrong."}</h4>
    </div>
  );
  
  return (
    <>
      <div className="container content-lg">
        <div className="row margin-b-40">
          <div className="col-sm-6">
            <h2>Writing</h2>
          </div>
        </div>
        <div className="row">
          {sections.length > 0 ? display : helper}
        </div>
      </div>
    </>
  )
}
