import { baseUrl, folder } from '../images';
import styles from './Contact.module.css';

const image= "lina2_fju303.jpg"

export default function Contact() {
  return (
    <div className="container content-sm">
      <div className="row">
        <div className="col-md-5 col-xs-12 sm-margin-b-30">
          <img
            className={styles.contact}
            alt='Lina Batarags'
            src={`${baseUrl}${folder}${image}`}
            srcSet={`
              ${baseUrl}w_280,q_auto,f_auto/${folder}${image}   280w,
              ${baseUrl}w_560,q_auto,f_auto/${folder}${image}   560w,
              ${baseUrl}w_840,q_auto,f_auto/${folder}${image}   840w,
              ${baseUrl}w_1100,q_auto,f_auto/${folder}${image} 1100w,
              ${baseUrl}w_1600,q_auto,f_auto/${folder}${image} 1600w,
            `}
            sizes='(max-width:1023px) 80vw, (min-width:1024px) and (max-width:1620px) 67vw, 1100px'
          />
        </div>
        <div className="col-md-7 col-sm-12 sm-margin-b-30">
          <div className="section-seperator margin-b-30">
            <div className="margin-b-20">
              <div className="margin-b-20">
                <h2>Contact Me</h2>
              </div>
            </div>
          </div>
          <div className="margin-b-30">
            <h4>Email</h4>
            <a className="margin-bottom" href="mailto:contact@linabatarags.com">contact@linabatarags.com</a>
          </div>
          <div className="margin-b-30">
            <h4>LinkedIn</h4>
            <a href="https://www.linkedin.com/in/lina-batarags/" target="blank">@lina-batarags</a>
          </div>
          <div className="margin-b-30">
            <h4>Twitter</h4>
            <a href="https://twitter.com/LinaBatarags" target="blank">@LinaBatarags</a>
          </div>
          <div className="margin-b-30">
            <h4>Location</h4>
            <p className="fake-link">Singapore</p>
          </div>
        </div>
      </div>
    </div>
  )
}
