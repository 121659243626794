import { baseUrl, folder } from '../images';
import styles from './Bio.module.css';

const image = "lina_i17qct.jpg";

export default function Bio() {
  return (
    <>
      <div className='container content-lg'>
        <div className='row'>
          <div className='col-lg-8 margin-b-40'>
            <img
              className={styles.bio}
              alt='Lina Batarags'
              src={`${baseUrl}${folder}${image}`}
              srcSet={`
                ${baseUrl}w_280,q_auto,f_auto/${folder}${image}   280w,
                ${baseUrl}w_560,q_auto,f_auto/${folder}${image}   560w,
                ${baseUrl}w_840,q_auto,f_auto/${folder}${image}   840w,
                ${baseUrl}w_1100,q_auto,f_auto/${folder}${image} 1100w,
                ${baseUrl}w_1600,q_auto,f_auto/${folder}${image} 1600w,
              `}
              sizes='(max-width:1023px) 80vw, (min-width:1024px) and (max-width:1620px) 67vw, 1100px'
            />
          </div>
          <div className='col-lg-4'>
            <div className='section-seperator margin-b-40'>
              <h2>I'm a writer and an editor.</h2>
            </div>
            <div className='margin-b-40'>
              <h4 className='normal'>
                I write stories about the ways people live, travel, and spend.
                I've built teams from the ground up in three newsrooms and two
                countries.
              </h4>
              <h4 className='normal'>
                Right now, I'm based in Singapore, where I'm building the first
                Asia bureau for Insider Inc. Before this, I lived in Brooklyn.
              </h4>
              <h4 className='normal'>
                I'm fluent in Latvian and not quite fluent in French.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
