import { baseUrl, folder, images } from '../images';

export default function Article({image, altText, source, title, description, articleLink}) {
  return (
    <div className='col-lg-4 col-md-6 sm-margin-b-50'>
      {image && (
        <div className='margin-b-20'>
          <div
            className='wow zoomIn text-center'
            data-wow-duration='.3'
            data-wow-delay='.1s'
          >
            <img
              className='img-responsive portfolio-img'
              // onError={(event) => {
              //   event.target.src = '';
              //   event.target.alt = '';
              // }}
              src={image.includes("http") ? image : images[image]}
              srcSet={
                image.includes("http")
                  ? null
                  : `
                ${baseUrl}w_280,q_auto,f_auto/${folder}${images[image]}   280w,
                ${baseUrl}w_560,q_auto,f_auto/${folder}${images[image]}   560w,
                ${baseUrl}w_840,q_auto,f_auto/${folder}${images[image]}   840w,
                ${baseUrl}w_1100,q_auto,f_auto/${folder}${images[image]} 1100w,
                ${baseUrl}w_1600,q_auto,f_auto/${folder}${images[image]} 1600w,
              `
              }
              sizes='(max-width:1023px) 80vw, (min-width:1024px) and (max-width:1620px) 67vw, 1100px'
              alt={altText}
            />
          </div>
        </div>
      )}
      <h4>
        <a href={articleLink} target='blank'>
          {title}
        </a>
      </h4>
      <p className='text-uppercase'>{source || "Business Insider"}</p>
      <p>{description}</p>
      <a href={articleLink} target='blank'>
        Read More
      </a>
    </div>
  );
}
