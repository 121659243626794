export default function Highlight({title, description, icon}) {
  return (
    <div className="col-lg-3 col-md-6 md-margin-b-4">
      <div className="service" data-height="height">
        <div className="service-element">
          <h2><i className={icon}></i></h2>
        </div>
        <div className="service-info">
          <h3>{title}</h3>
          <p className="margin-b-5">{description}</p>
        </div>
      </div>
    </div>
  )
}
