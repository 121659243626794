// Replace spaces in section name with dashes
const transformPath = (path) => {
  return path.replaceAll(" ", "-");
};

// Add 'current-page' className to current/active path
const checkCurrentPath = (path, section, firstSection) => {
  if (path === '/writing' && section === firstSection) {
    return 'current-page';
  }

  return path.includes(section) && 'current-page';
};

export { checkCurrentPath, transformPath };
