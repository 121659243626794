import { useEffect, useState } from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import NavBar from "./components/NavBar/NavBar";
import ScrollToTop from "./components/ScrollToTop";
import Writing from "./components/Writing/Writing";
import WritingSection from "./components/Writing/WritingSection";
import { transformPath } from "./components/pathHelper";
import "./App.css";

function App() {
  const [DATA, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const sections = Object.keys(DATA);
  const firstSection = sections[0];

  useEffect(() => {
    console.log('Fetching portfolio...');

    const fetchData = async () => {
      if (!isLoading) {
        setIsLoading(true);
      }

      try {
        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbyrhjm7K9W8mI7OKSDLebxz89Hu1lfkSnQZTAyNxJdEjVB2fcag-1qneC5KinmmnmquFw/exec"
        );
        const json = await response.json();
        setData(json);
      } catch {
        console.error("Something went wrong...")
      }
  
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <NavBar />
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<About sections={sections} isLoading={isLoading} />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/writing' element={<Writing sections={sections} />}>
            <Route index element={
              <WritingSection title={firstSection} articles={DATA[firstSection]} isLoading={isLoading} />
            } />
            {sections.map(section => {
              let path = transformPath(section);
              return <Route path={path} key={section} element={
                <WritingSection title={section} articles={DATA[section]} isLoading={isLoading} />
              } />
            })}
            <Route path='*' element={<Navigate to='/writing' replace />} />
          </Route>
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </ScrollToTop>
    </>
  );
}

export default App;
