export default function Education() {
  return (
    <>
      <div className="bg-color-sky-light">
        <div className="container content-lg" data-auto-height="true">
          <h2>Education</h2>
          <h4>Bachelor of Arts, English &amp; French</h4>
          <h5>Lafayette College, Easton, Pennsylvania</h5>
          <p>Semester on Film and Environmentalism, <i>University of Auckland, Auckland, New Zealand</i></p>
        </div>
      </div>
    </>
  )
}
